<template>
  <div class="thePage">
    <div class="page-header">
      <p>
        <strong>收藏列表</strong>
      </p>
    </div>

    <div class="page-main">
      <el-input class="searchInput" type="text" v-model="kw" placeholder="请输入关键字搜索">
        <i slot="suffix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-tabs v-model="tabBox" @tab-click="handleClick">
        <el-tab-pane label="收藏的任务" name="first" v-loading="tabLoading">
          <el-empty description="暂无收藏记录" />
        </el-tab-pane>
        <el-tab-pane label="收藏的服务" name="second" v-loading="tabLoading">
          <el-empty description="暂无收藏记录" />
        </el-tab-pane>
        <el-tab-pane label="自由职业者" name="third" v-loading="tabLoading">
          <el-empty v-if="freelancerList.length==0" description="暂无收藏记录" />
          <div v-if="freelancerList.length>0">
            <TalentsItem :mode="'collect'" :list="item" v-for="(item, index) in freelancerList" :key="index" @collect="toCollect" @toinfo="toInfo"/>
            <el-pagination
                class="pageChangeBar"
                @size-change="handleSizeFreelancerChange"
                @current-change="handlePageFreelancerChange"
                :current-page="freelancerPagination.page"
                :page-size="freelancerPagination.page_size"
                layout="prev, pager, next,->,total,jumper"
                :total="freelancerPagination.total"
                background
                :hide-on-single-page="true"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {TalentsItem} from "@/_components/PersonItem";
import {getMyCollect} from "@/api/user";
import {collectFreelancer, unCollectFreelancer} from "@/api/freelancer";
import {getToken} from "@/utils/auth";

export default {
  name: "index",
  components: {
    TalentsItem
  },
  data() {
    return {
      tabBox: 'first',
      kw: '',
      tabLoading: false,
      taskList: [],
      taskPageParams: {page: 1, page_size: 10, type: 'task', kw: ''},
      freelancerList: [],
      freelancerPageParams: {page: 1, page_size: 10, type: 'freelancer', kw: ''},
      freelancerPagination: {page: 1, page_size: 10, total: 0},
      serviceList: [],
      servicePageParams: {page: 1, page_size: 10, type: 'service', kw: ''},
      collectBtnCanToDo: true
    }
  },
  created() {
    this.loadMyCollentions(this.taskPageParams)
  },
  methods: {
    handleClick(tab) {
      switch (tab.name) {
        case 'first':
          this.loadMyCollentions(this.taskPageParams)
          break;
        case 'second':
          this.loadMyCollentions(this.servicePageParams)
          break;
        case 'third':
          this.loadMyCollentions(this.freelancerPageParams)
          break;
      }
    },
    async loadMyCollentions(data) {
      this.tabLoading = true
      const res = await getMyCollect(data)
      if (res.statusCode == 200) {
        switch ( data.type ){
          case 'task':
            this.taskList = res.data.list
            break;
          case 'service':
            this.serviceList = res.data.list
            break;
          case 'freelancer':
            this.freelancerList = res.data.list
            break;
        }

        this.tabLoading = false
      }else{
        this.tabLoading = false
      }
    },

    toCollect( freelancer ) {
      if( !getToken() ){
        this.$store.dispatch( 'useUserStore/needLoginModal', true )
        return;
      }
      if( !this.collectBtnCanToDo ) {
        return;
      }
      this.collectBtnCanToDo = false// 关闭防止连续点击
      var that = this
      setTimeout(function() {
        that.collectBtnCanToDo = true
      }, 2000)
      if( freelancer.is_collect == 1 ){
        this.unCollect( { freelancer_id: freelancer.id }, freelancer )
      }else{
        this.collect( { freelancer_id: freelancer.id }, freelancer )
      }
    },
    async collect(data, freelancer){
      const res = await collectFreelancer(data)
      if( res.statusCode == 200  ){
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已收藏 ['+freelancer.name+']'
        })
        freelancer.is_collect = 1
      }else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        })
      }
    },
    async unCollect(data, freelancer){
      const res = await unCollectFreelancer(data)
      if( res.statusCode == 200  ){
        this.$notify({
          type: 'success',
          title: '通知',
          message: '已取消收藏 ['+freelancer.name+']'
        })
        freelancer.is_collect = 0
      } else if ( res.statusCode == 511 ){
        this.$notify({
          type: 'warning',
          title: '通知',
          message: '请先登录!'
        })
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        })
      }
    },

    toInfo(obj) {
      this.$router.push('/freelancer/info/' + obj.id);
    },
    handleSizeFreelancerChange(val){
      this.freelancerPageParams.page_size = val
      this.loadMyCollentions(this.freelancerPageParams)
    },
    handlePageFreelancerChange(val){
      this.freelancerPageParams.page = val
      this.loadMyCollentions(this.freelancerPageParams)
    }
  },
}
</script>

<style lang="less" scoped>
.thePage {
  position: relative;
  background-color: #fff;
  min-height: 930px;

  .page-header {
    height: 76px;
    border-bottom: 1px solid #b5b5b5;
    display: flex;

    p {
      font-size: 18px;
      margin: 40px;
    }
  }

  .page-main {
    margin-top: 20px;
    background-color: #fff;
    padding: 0 40px;
    .pageChangeBar{
      margin: 20px 0px;
    }
  }
}

.searchInput {
  width: 200px;
  position: absolute;
  z-index: 2;
  right: 40px;
}

</style>
